/* Add this to the top of your CSS file (e.g., styles.css) */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

/* General Header Styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px 8px 32px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
  background-color: #fef3f2;
}

.header-sign-out {
}
.header-sign-in {
  background-color: transparent !important;
}

.header.visible {
  transform: translateY(0);
}

.header.hidden {
  transform: translateY(-100%);
}

.leftSection {
  display: flex;
  align-items: center;
}
.menuIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px; /* Adjust width as needed */
  height: 40px; /* Adjust height as needed */
  cursor: pointer;
  transition: box-shadow 0.3s ease; /* Add transition for smooth effect */
  border-radius: 6px; /* Round edges */
}

.menuIcon {
  font-size: 24px; /* Adjust icon size as needed */
}

/* Light grey outline on hover */
.menuIconContainer:hover {
  border: 1px solid rgb(230, 230, 230); /* Light grey outline */
}

/* Softened inner shadow when clicked (panel appears) */
.menuIconContainer.active {
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1); /* Softer inner shadow */
  border: 1px solid rgb(238, 238, 238); /* Add border to show pressed state */
  border-radius: 6px; /* Round edges */
}
.HeaderLogo img {
  height: 40px;
  margin-right: 10px;
}

.subMenu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.subMenu li {
  margin-right: 20px;
}

.subMenu li a {
  color: #262624;
  text-decoration: none;
}

.subMenu li:hover {
  background-color: #fff5f0;
}

.rightLinks {
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  align-items: center;
  margin-right: 32px; /* Add margin to the rightmost item */
}

.rightLinks a,
.rightLinks span {
  text-decoration: none;
  color: inherit;
  background: none;
  border: none;
  cursor: pointer;
  font: inherit;
  padding: 8px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.rightLinks a:hover,
.rightLinks span:hover {
  background-color: #f5e9e8;
}

.rightLinks a,
.rightLinks span {
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}
/* Menu Overlay Styles */
.menuOverlay {
  position: fixed;
  top: 64px; /* Adjust to place the panel right below the header */
  left: -320px; /* Set to align with the hamburger menu */
  height: auto; /* Adjust to fit content */
  width: 320px; /* Set width to 320px */

  transition: transform 0.6s ease-in-out; /* Speed up the slide-in animation */
  z-index: 960; /* Ensure it stays below the header */
  border-radius: 12px;
  transform: translateX(0%); /* Initially hidden */
  box-shadow: 0 10px 20px #0003;
}

.menuOverlay.open {
  transform: translateX(354px); /* Slide it into view */
}

/* Adjust .menuContent as well */
.menuContent {
  height: auto; /* Adjust to fit content */
  width: 320px;
  background: linear-gradient(135deg, #f1deee, #fdf3f2, #e8ebc2);
  color: black; /* Set font color to black */
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-family: "Inter", sans-serif;
  border-radius: 12px;
}
.menuContent ul {
  list-style: none;
  padding: 0;
  margin: 0;
  flex: 1;
}

.menuContent li {
  display: flex;
  align-items: center; /* Ensure vertical center alignment */
  padding: 15px 0;
  font-size: 18px;
  border-bottom: 1px solid var(--navbar-dark-secondary);
  cursor: pointer; /* Make the entire row clickable */
  transition: all 0.3s ease-in-out;
}

.menuContent li:hover {
  background-color: rgba(0, 0, 0, 0.05); /* Adjust hover contrast */
  border-radius: 10px; /* Add rounded edge on hover */
  padding-left: 4px; /* Increase left padding on hover for balance */
}

.menuContent li a,
.menuContent li button {
  display: flex;
  align-items: center; /* Ensure vertical center alignment */
  flex-direction: row;
  gap: 16px;
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  font: inherit;
  padding: 0;
  text-decoration: none;
  color: black;
}

.menuContent li i {
  margin-right: 10px; /* Space between the icon and the label */
}

.menuHeader {
  display: none; /* Remove the header and close button */
}

.HeaderLogo {
  align-items: center;
  margin-left: 12px;
}

.HeaderLogoText {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 26px;
  background-color: #333;
  background-size: 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
}

.header-content-bottom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 16px;
}

.header-content .button-md {
  margin-left: 10px; /* Space between the buttons */
}

.header-content-bottom .button-md {
  margin-top: 10px; /* Space above the "+ Add" button */
}

.header,
.headerContainer {
  width: 100%;
}

.headerContainer h1,
.header h1 {
  margin: 16px;
}

.headerContainer buttons,
.header buttons {
  background-color: #ffffff;
  color: #0f6cd7;
  border: none;
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
  text-align: right;
  float: right;
}

.headerContainer buttons:hover,
.header buttons:hover {
  background-color: #f0f0f0;
}

.rightLinks a,
.rightLinks span {
  text-decoration: none;
  color: inherit;
  background: none;
  border: none;
  cursor: pointer;
  font: inherit;

  border-radius: 6px;
  display: flex;
  align-items: center; /* Ensure vertical center alignment */
  gap: 8px; /* Space between icon and label */
}

.menuContent li {
  display: flex;
  align-items: center; /* Ensure vertical center alignment */
  padding: 15px 0;
  font-size: 18px;
  border-bottom: 1px solid var(--navbar-dark-secondary);
  cursor: pointer; /* Make the entire row clickable */
  transition: background-color 0.3s, border-radius 0.3s;
}

.menuContent li i {
  margin-right: 16px; /* Space between icon and label */
}

.menuContent li a,
.menuContent li span {
  display: flex;
  flex-direction: row;
  gap: 16px;
  vertical-align: middle;
  padding-left: 8px;
  transition: padding-left 0.5s ease-in-out, background-color 0.5s ease-in-out; /* Add smooth animation for padding and background color */
  align-items: center; /* Vertically center align items */
}

.menuContent i {
  display: flex;
  align-items: center; /* Vertically center align items */
}

.subheader {
  position: fixed;
  top: 56px;
  left: 50%; /* Position element relative to the center of the viewport */
  transform: translateX(-50%); /* Center the element horizontally */
  width: 100vw; /* Full width of the viewport */
  background-color: #fffbf9;
  z-index: 950; /* Ensure it stays on top of other elements */
  display: flex;
  justify-content: space-between;
  align-items: center; /* Fix the alignment issue */
  padding: 10px 40px;
  box-sizing: border-box; /* Ensure padding doesn't expand the element */
  transition: background-color 0.3s ease, padding-left 0.5s ease-in-out; /* Add smooth animation */
  gap: 16px;
}

.subheader h2 {
  text-align: left;
  padding: 0px;
  margin: 0px;
  font-weight: 400;
}

.subheader-button-group {
  gap: 8px;
  display: flex;
  flex-direction: right;
}

.pro-button {
  background-color: #fff5f0;
  border: none;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #262624;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.9s ease-in;
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
}

.pro-button i {
  margin-right: 8px;
}

.pro-button:hover {
  background-color: #f5e9e8;
}

.pro-button span {
  opacity: 0;
  width: 0;
  transition: all 0.6s ease-in;
}

.pro-button:hover span {
  opacity: 1;
  width: auto;
  margin-left: 8px;
}

.addFaqButton {
  display: flex;
  align-items: center;
}

/* Start Button Styles */

.start-button-header {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background: linear-gradient(135deg, #fdf3fc, #fff9f9, #feffeb);
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(161, 161, 161, 0.2);
  color: #333;
  cursor: pointer;
  display: flex;
  align-items: center; /* Align items vertically */
  font-size: 16px;

  overflow: hidden;
  position: relative;
  text-align: center;
  transition: transform 0.5s ease-in, opacity 0.5s ease-in;
  padding: 12px;
  vertical-align: middle;
  border: 0px !important;
  gap: 8px;
}

.start-button-header i {
  font-size: 18px; /* Adjust icon size if needed */
}

.start-button-header span {
  display: flex;
  align-items: center; /* Align text vertically */
}

.start-button-header.animate {
  opacity: 1;
}

.start-button-header:hover {
  background: linear-gradient(135deg, #e8ebc2, #fdf3f2, #f1deee);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.start-button-header:active {
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.start-button-header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    135deg,
    rgba(248, 243, 242, 0.5),
    rgba(241, 222, 238, 0.5)
  ); /* Gradient for hover effect */
  z-index: -1; /* Place the gradient behind the text */
  transition: opacity 0.5s ease; /* Smooth transition for gradient flip */
  opacity: 0;
}

.start-button-header:hover::before {
  opacity: 1; /* Show the gradient on hover */
}
