/* SignIn Container */
.signin-container {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items to the top */
  height: 100vh;
  background-color: white;
  border-radius: 12px;
  width: 100%;
  padding: 0;
  margin: 0;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  font-family: Inter, Helvetica, sans-serif;
}

/* Brand Names */
.brand-name,
.brand-name1 {
  text-align: left;
  margin-bottom: 24px;
  color: #989898; /* Facebook blue */
  font-weight: 600;
  margin-top: 8px;
}

.brand-name1 {
  text-align: center !important;
  color: #2f2f2f !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  margin-top: 0px !important;
}

/* Input Labels */
.signinLabel {
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 4px;
  display: block;
  text-align: left;
}

/* Input Fields */
.email-input,
.password-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 12px;
  background-color: #fff !important;
}

.password-input {
  margin-bottom: 14px;
}

/* Buttons */
.signin-button {
  width: 100%;
  height: 40px;
  padding: 10px;
  border: none;
  border-radius: 8px;
  background-color: #484848;
  color: white;
  font-size: 14px;
  cursor: pointer;
  margin-top: 12px;
  font-family: Inter, Helvetica, sans-serif;
}

/* Logo */
.logo {
  text-align: center; /* Center the content horizontally */
  margin-bottom: 24px; /* Add some space below the logo */
  background-image: url("https://media.discordapp.net/attachments/1097690822578819093/1234322394475139214/goforthree_robot_shiba_inu_mini_minimalistic_muji_like_simple_f_ac888722-5a78-4d6d-bd08-d485c90eecda.png?ex=66304fcf&is=662efe4f&hm=ad552c7365ba8537da26d9b6ac33839aacac955e64c52ad83bb94053d17c121a&=&format=webp&quality=lossless&width=569&height=569");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 200px;
}

/* Links */
.link-container,
.link-container-solo {
  display: flex;
  justify-content: space-between;
  margin-top: 22px;
}

.left-link,
.center-link,
.right-link {
  color: #333;
  font-family: Inter, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.left-link {
  text-align: left;
}

.center-link {
  text-align: center !important;
}

.right-link {
  text-align: right;
}

/* Demo Container */
.demo-container {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Enable vertical scrolling */
}

/* SignIn Module */
.signinModule {
  position: absolute;
  background-color: antiquewhite;
}

/* Close Button */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.close-button:hover {
  color: #000;
}

/* Drop Background */
.dropBG {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
}

.form-container {
  width: 460px;
  padding: 40px;
  box-sizing: border-box;
  background: linear-gradient(135deg, #f1deee, #fdf3f2, #e8ebc2);
  position: relative;
  border-radius: 8px;
}

.dropBG.active {
  display: flex; /* Show when active */
}

.closeButton {
  position: absolute;
  top: 16px;
  right: 16px;
  border: none; /* Optional, removes the border */
  background: transparent; /* Optional, makes the background transparent */
  cursor: pointer; /* Changes the cursor to a pointer when hovering over the button */
  font-size: 16px; /* Adjusts the size of the 'x', increase if you want it larger */
}

.closeButton span {
  color: #474747;
  font-size: 20px;
}

.HeaderLogo img {
  height: 40px;
  width: 40px;
  margin-top: 4px;
}

.error-message {
  color: red;
}

.SignInLogo {
  display: flex; /* Enables flexbox layout */
  align-items: center; /* Vertically aligns the children to the middle */
  justify-content: center; /* Horizontally centers the content */
  text-align: center; /* Ensures text is centered, useful if flex properties fail */
  margin-bottom: 40px;
}

.SignInLogo img {
  width: 50px;
}

.brand-name-2 {
  margin-bottom: 8px !important;
  color: #2f2f2f !important; /* Facebook blue */
  font-family: Inter, Helvetica, sans-serif !important;
  font-weight: 600 !important;
  font-size: 32px !important;
  margin-top: 0px !important;
}

/* SignIn Modal Styles */
.dropBG {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Grey transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000; /* Ensure the modal has a higher z-index than the header */
}

.form-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  position: relative;
  z-index: 2100; /* Ensure the form container is above the backdrop */
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.error-message {
  color: red;
  margin-top: 10px;
  font-size: 14px;
}

.start-button-sign {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background: linear-gradient(135deg, #f1deee, #fdf3f2, #e8ebc2);
  border: 2px solid #f8f3f24d;
  border-radius: 15px;
  box-shadow: 0 10px 20px #0003;
  color: #333;
  cursor: pointer;
  display: inline-block;
  display: block;
  font-size: 20px;
  margin: 48px auto;
  overflow: hidden;
  padding: 18px 56px;
  transition: transform 0.5s ease-in, opacity 0.5s ease-in;
}
