.pageSettings {
  display: flex;
  align-items: center; /* Align items to the center vertically */
  flex-direction: row;
  gap: 8px;
  border-bottom: 1px solid rgb(232, 232, 232);
  padding: 32px;
}
.pageSettings label,
.bannerSetting label {
  font-size: 18px;
  font-weight: 500;
  margin: 0px !important;
}

.page-settings-content {
  margin-top: 10px;
  margin-bottom: 20px;
}

.bannerSetting {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 32px;
}

.pageSettings input[type="text"] {
  width: auto !important;
}

button.button-md.settings {
  /* Add your styles here */
  background-color: #4caf50; /* Example background color */
  color: white; /* Example text color */
  border: none; /* Removes border */
  padding: 8px 16px;
  text-align: center; /* Centers text */
  text-decoration: none; /* Removes underline */
  display: inline-block; /* Keeps the button inline */
  font-size: 16px; /* Example font size */
  margin: 4px 2px; /* Example margin */
  cursor: pointer; /* Changes cursor to pointer */
  border-radius: 6px; /* Example border radius for rounded corners */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}
