.analytics-container {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 32px;
}

.analytics-background-box {
  flex: 1;
  background: #f3f3f3;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 120px;
}

.analytics-text-header {
  color: black;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  margin-bottom: 8px;
  text-align: center;
}

.analytics-text-value {
  color: black;
  font-size: 32px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  text-align: center;
}

.mostViewedFAQs-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}

.chatHistory {
  margin-bottom: 8px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 8px;
}

.chatHistory:hover {
  background-color: aliceblue;
}

.mostViewedFAQs-container {
  margin-bottom: 32px;
}

.mostViewedFAQs-container label {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 4px;
  display: block;
  text-align: left;
}

.fixed-width-table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 6px;
  overflow: hidden;
}

.fixed-width {
  width: 90%; /* Set the width to 50% of the table's width */
  text-align: left;
  padding: 8px;
  border: 1px solid #ddd;
}
.fixed-width-clicks {
  width: 10%; /* Set the width to 50% of the table's width */
  padding: 16px;
  border: 1px solid #ddd;
  text-align: right;
  margin-right: 8px;
}
.fixed-width-clicks td th {
  margin-right: 8px;
}

.fixed-width-table th {
  background-color: #f2f2f2;
  font-weight: 400;
  background-color: #f8f8f8; /* Slightly grey background for headers */
}

.fixed-width-table td,
.fixed-width-table th {
  box-sizing: border-box;
}

.chatHistory .chevron {
  font-size: 18px;
  color: #000;
  font-weight: 300;
  flex-shrink: 0;
  text-align: right;
  margin-left: 10px;
}

.visitorText {
  flex: 1;
  text-align: left;
}

.visitorId {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px;
}

.metaData-container {
  max-width: 1200px;
}
