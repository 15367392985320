.content-card {
  width: 100%; /* Set width to auto for horizontal card layout */
  height: 60px; /* Set height to auto for dynamic content */
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  padding: 0;
  background-color: white;
  display: flex;
  flex-direction: row; /* Set flex direction to row for horizontal layout */
  justify-content: space-between; /* Align items to the ends */
  overflow: hidden; /* Keeps content within the card */
  margin-top: 14px;
}

.card-data {
  display: flex; /* Use flexbox */
  align-items: center; /* Align content vertically to the center */
  flex: 1; /* Take up remaining space */
}

.card-data h3 {
  margin: 16px; /* Remove default margin */
  font-size: 16px;
  font-weight: 400;
}

.card-data p {
  margin: 10px 0; /* Add some margin between title and description */
}

/* Style for buttons */
.content-card button {
  background-color: white;
  margin: 16px 16px 16px 16px;
  color: #535353;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  outline: none;
  align-items: center;
  font-size: 16px;
}

.card-actions {
  align-items: center;
}

.content-card:hover {
  background-color: #fff5f0;
  cursor: pointer;
}

.empty-state {
  background-color: #fcf4f2;
  border: 2px dashed #ccc;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 20px 0;
  padding: 40px;
  text-align: left;
  width: 400px;
  height: 400px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: space-evenly; /* or space-around */
}

.empty-state h2 {
  font-weight: 400;
  font-size: 36px;
}

.empty-state h3 {
  font-weight: 400;
  font-size: 28px;
}

.empty-container h2 {
  font-weight: 400;
  font-size: 28px;
}

.empty-state p {
  font-size: 18px;
  margin-bottom: 20px;
}

.empty-state .button-md {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.empty-state .button-md:hover {
  background-color: #45a049;
}

.DeleteModal .button-md {
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 16px;
  background-color: #a3a3a3;
}

.empty-state-container {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center; /* or space-around */
  gap: 16px;
}

.empty-image {
  width: 400px;
}

.empty-state img {
  width: 300px;
}

.empty-faq-h2 {
  font-size: 36px;
  font-weight: 400;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 350px;
  margin-top: 64px;
  margin-bottom: 64px;
}

.link-button {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  width: 100%;
}
