/* General Styles */
.DemoPage {
  font-family: "Inter", sans-serif;
  margin: 60px 0 90px;
  text-align: center;
}

.DemoPage h1,
.DemoPage h2,
.DemoPage h3 {
  padding: 16px;
  text-align: center;
}

.DemoPage h1 {
  font-size: 72px;
  font-weight: 100;
  margin-top: 40px;
  line-height: 1.2;
}

.DemoPage h2 {
  font-size: 20px;
  font-weight: 400;
}

.DemoPage h3 {
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  margin-top: 0;
}

.date {
  display: block;
  font-size: 16px;
  font-style: italic;
  margin-bottom: 20px;
}

/* Accordion Styles */
.ContentAccordion {
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

.accordion-header {
  background-color: #f7f7f7;
  cursor: pointer;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-header:hover {
  background-color: #e0e0e0;
}

.accordion-content {
  padding: 24px;
  border-top: 1px solid #ccc;
}

/* Hero Images */
.HeroImages {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.HeroImages img {
  max-width: 50%;
  height: auto;
}

.HeroImages div > div {
  flex: 1;
}

.HeroImages h4 {
  margin: 0;
  padding-top: 0;
}

@media (max-width: 768px) {
  .HeroImages {
    flex-direction: column;
  }
  .HeroImages img {
    max-width: 100%;
    margin-bottom: 8px;
  }
}

/* Section Styles */
.demoSection {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.sectionCopy {
  margin-left: 16px;
  display: flex;
  flex-direction: column;
}

.sectionCopy h4 {
  font-size: 26px;
}

/* HR Styles */
hr {
  margin: 64px 0 32px;
}

.sectionTitle h3 {
  font-size: 32px;
}

/* Demo Header */
.DemoHeader {
  background-color: white;
  padding: 32px 16px;
}

.DemoHeader h1 {
  padding: 0;
  margin-top: 0;
  font-size: 80px;
  font-weight: 500;
}

.DemoHeader h2 {
  padding: 0;
  margin-top: 32px;
  font-size: 48px;
  font-weight: 300;
}

/* Content Block */
.ContentBlock {
  position: absolute;
  top: 660px; /* Distance from the top of the screen */
  left: 50%; /* Center from the left edge */
  transform: translateX(-50%); /* Offset by half of its width to center */
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin: 20px;
}

.column {
  flex: 1;
  padding: 10px;
  text-align: center;
}

.column img {
  max-width: 100%;
  height: auto;
}

/* General Text Styles */
h4 {
  margin: 10px 0;
}

p {
  color: gray;
}

.demo-container {
  width: 1200px;
}

/* Analytic and FAQ Block */
.AnalyticBlock,
.FAQBlock {
  padding: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: white;
}

.FAQContainer {
  width: 750px;
}

.AnalyticContainer {
  width: 450px;
}

/* Video Background */
.video-background-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: -1;
}

.video-background {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1;
}

/* Content Overlay */
.content {
  position: relative;
  z-index: 1;
  color: white;
  text-align: center;
  padding: 20px;
}

/* DemoPage.css */

/* Start Button Styles */
.start-button {
  display: inline-block;
  padding: 18px 56px;
  font-size: 20px;
  color: #333; /* Dark text color for contrast */
  background: linear-gradient(135deg, #f1deee, #fdf3f2, #e8ebc2);
  border: 2px solid rgba(248, 243, 242, 0.3); /* Light border with transparency */
  border-radius: 15px;
  backdrop-filter: blur(10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  text-align: center;
  position: relative;
  overflow: hidden;
  margin: 48px auto;
  display: block;
  opacity: 0;
  transform: translateY(100px); /* Start 100px below the original position */
  transition: transform 0.5s ease-in, opacity 0.5s ease-in;
}

.start-button.animate {
  opacity: 1;
  transform: translateY(0); /* Move to the original position */
}

/* Additional styles for other elements if necessary */
.header-container {
  text-align: center;
  padding: 20px;
}

.ContentBlock {
  padding: 20px;
}
.start-button:hover {
  background: linear-gradient(135deg, #e8ebc2, #fdf3f2, #f1deee);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.3);
}

.start-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    135deg,
    rgba(248, 243, 242, 0.5),
    rgba(241, 222, 238, 0.5)
  ); /* Gradient for hover effect */
  z-index: -1; /* Place the gradient behind the text */
  transition: opacity 0.5s ease; /* Smooth transition for gradient flip */
  opacity: 0;
}

.start-button:hover::before {
  opacity: 1; /* Show the gradient on hover */
}

.start-button:active {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #e8ebc2; /* Light background color */
}

.content {
  height: 2000px; /* Large height to enable scrolling */
  background: linear-gradient(to bottom, #f1deee, #e8ebc2);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
