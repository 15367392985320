/* IframesModal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  width: 560px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center; /* Ensures content like the h2 is centered */
}

.modal-textarea {
  width: 100%;
  height: 150px;
}

.modal-buttons {
  margin-top: 20px; /* Adds space between the textarea and the buttons */
  display: flex;
  justify-content: center; /* Centers buttons horizontally */
}

.modal-buttons button {
  background: none;
  border: 1px solid grey;
  padding: 8px 16px; /* Adjust padding to your preference */
  margin-right: 10px; /* Adds space between the buttons */
  cursor: pointer;
}

.modal-buttons button:last-child {
  margin-right: 0; /* Removes margin from the last button */
}

.modal-h2 {
  color: black; /* Sets the color of h2 headings in the modal */
  margin-bottom: 20px; /* Adds space below the h2 */
}

.iframeHolder {
  margin-top: -140px;
}
