/* ViewerPage.css */
body {
  font-family: Inter, Helvetica, sans-serif; /* Added font family */
}

.viewer-app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: -140px;
}

.chatbot-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: #333; /* Adjust color to match your design */
  font-size: 24px;
  cursor: pointer;
}

@media (max-width: 600px) {
  .ViewerHeader {
    width: 100vw;
    background-size: cover; /* Ensure the background image covers the entire header */
    background-position: center; /* Center the background image */
    color: white;
    text-align: center;
    vertical-align: middle;
    font-size: 20px; /* Larger font size */
    font-weight: bold; /* Bold font */

    height: 300px;
  }
  .viewer-content {
    display: flex;
    flex-direction: column; /* Starts as a single column for mobile-first approach */
    align-items: flex-start;
    width: 100%;
  }

  .viewer-chatbot-container.active .chatbot-close-btn {
    display: block; /* Only show close button when chatbot is active and on mobile */
  }

  .categoryList {
    margin: 16px 0px 16px 8px;
  }
  .page-title {
    font-size: 18px;
    margin-left: 16px;
  }

  .categoryPill {
    border: 1px solid #d3d3d3;
    background-color: #fff;
    border-radius: 20px;
    padding: 5px 10px;
    margin-right: 3px;
    margin-bottom: 3px;
    cursor: pointer;
    font-size: 14px;
  }

  .viewer-chatbot-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: white;
    transform: translateY(100%); /* Start hidden */
    transition: transform 0.5s ease-in-out;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000; /* Ensure it's above other content */
  }

  .viewer-chatbot-container.active {
    transform: translateY(0); /* Slide up to show */
    width: 100%;
  }

  .chatbot-toggle {
    display: block; /* Only show toggle on mobile */
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
}

@media (min-width: 600px) {
  .ViewerHeader {
    background-position: 50%;
    background-size: cover;
    color: #fff;
    font-size: 36px;
    font-weight: 700;
    height: 160px;
    text-align: center;
    width: 100vw;
    display: flex; /* Enable Flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
  }
  .viewer-content {
    display: flex;
    flex-direction: row; /* Starts as a single column for mobile-first approach */
    align-items: flex-start;
    width: 100%;

    .viewer-faq-column {
      border-right: 1px solid rgb(224, 224, 224);
      padding: 20px;
      flex-grow: 1; /* Takes up the remaining space if right panel is not present */
      flex-basis: 0; /* Starts at 0% width */
    }
  }

  /* Hide the right panel using a specific class toggled by your app logic */
  .container .right-panel.hidden {
    display: none;
  }

  .viewer-chatbot-container {
    width: 40% !important;
    flex-direction: column;
    align-items: flex-start; /* Aligns content to the top of the container */
    width: 100%; /* Adjust width as necessary */
  }
  .chatbot-close-btn {
    display: none;
  }

  .chatbot-toggle {
    display: none; /* Only show toggle on mobile */
  }
}

.viewer-chatbot-container h2 {
  padding: 20px;
}

.viewer-chatbot {
  width: 100%;
}

.content {
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
}

/* Style for the FAQModal */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 400px;
  border-radius: 5px;
}

.modal-content input {
  width: 100%;
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.modal-content button {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 10px 15px;
  cursor: pointer;
}

.modal-content button.cancel-button {
  background-color: #f44336;
}

/* ViewerPage.css */
.faq-answer {
  font-size: 18px;
  line-height: 32px;
  font-weight: 300;
}

.faq-question {
  display: flex; /* Use flexbox to separate text and arrow */
  justify-content: space-between; /* Space between text and arrow */
  align-items: center; /* Center items vertically */
  font-size: 22px !important; /* Font size for the question */
  font-weight: 400; /* Make question text bold */
  padding: 16px 16px; /* Padding for the question */
}

/* ViewerPage.css */

.ViewerHeader h2 {
  font-size: 56px;
  font-weight: 500;
  background: linear-gradient(
    45deg,
    rgb(255, 255, 255),
    rgb(202, 202, 202)
  ); /* Gradient colors */
  -webkit-background-clip: text; /* Apply the gradient to the text */
  -webkit-text-fill-color: transparent; /* Make the text transparent */
}
