.floating-squares-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: -1;
}

.floating-square {
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 40px;
  opacity: 0.3;
  display: block;
}

.square1 {
  background-color: #fdf3f2;
}

.square2 {
  background-color: #e8ebc2;
}

.square3 {
  background-color: #fdf3f2;
}
.square4 {
  background-color: #f1deee;
}
* {
  margin: 0px;
  padding: 0px;
}
