/* Global Styles */

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: Inter, Helvetica, sans-serif; /* Added font family */
}

label {
  font-size: 16px;
  font-weight: 600;
  font-family: Inter, Helvetica, sans-serif; /* Added font family */
  margin-top: 12px;
}

/* Global input field styling */
input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
input[type="search"],
textarea {
  font-size: 16px; /* Set font size to 16px */
  font-family: Inter, Helvetica, sans-serif; /* Added font family */
  font-weight: 400;
  color: #232323;
  padding: 12px; /* Add padding */
  border: 1px solid #ccc; /* Add border */
  border-radius: 8px; /* Add border radius */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
  width: 100%; /* Make input fields fill their container */
}

textarea {
  max-height: 450px;
  min-height: 300px;
}
input[type="checkbox"] {
  /* Increases the size of the checkbox */
  transform: scale(1.5);
  border: 1px solid grey;
  /* Adds some margin around each checkbox */
  margin: 10px;
  height: 20px;
  width: 20px;
  /* Changes the cursor to a pointer to indicate clickable area */
  cursor: pointer;
}

select {
  width: auto;
  padding: 12px 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  font-size: 16px;
  -webkit-appearance: none; /* Remove default styling */
  -moz-appearance: none; /* Remove default styling */
  appearance: none; /* Remove default styling */
  cursor: pointer;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

/* Customize placeholder text */
::-webkit-input-placeholder,
:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder {
  color: #999; /* Change placeholder text color */
}

/* Layout Styles */
.app-container {
  flex-direction: column;
  padding-left: 40px; /* Add left padding */
  padding-right: 40px; /* Add right padding */
  height: 100%;
  max-width: 1200px;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  overflow: visible;
  margin: 140px auto 0 auto;
  /*make sure you change IframeModal.css, .iframeHolder*/
  /*make sure to change , .viewer-app-container */
}

.headerContainer {
  display: flex;
  justify-content: space-between; /* Align items to the start and end of the container */
  align-items: center; /* Align items vertically in the container */
  width: 100%;
  background-color: #0f6cd7;
  color: white;
}

.content {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  height: 100%;
  width: 100%;
}

/* Header Styles */

.MainHeaders {
  display: flex;
  width: 100%;
}

.buttons {
  display: flex;
}

.buttons button {
  margin-left: 10px; /* Add some space between buttons */
}

/* Align the buttons to the end of the container */
.buttons button:last-child {
  margin-left: auto; /* Push the last button to the right */
}

/* Responsive Design */
@media (max-width: 768px) {
  .content {
    flex-direction: column;
  }

  .chatbot-container,
  .faq-list {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .faq-container,
  .chatbot-container {
    width: 100%;
  }
}

@media (min-width: 500px) {
  .faq-container,
  .chatbot-container {
    max-width: calc(100% - 10px);
  }
}

/* FAQ Styles */

/* FAQ Item Styles */
.faq-item {
  width: 100%;
  cursor: pointer; /* Enable mouse pointer cursor */
  padding: 0;
  font-family: Inter, Helvetica, sans-serif;
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.faq-question {
  display: flex; /* Use flexbox to separate text and arrow */
  justify-content: space-between; /* Space between text and arrow */
  align-items: center; /* Center items vertically */

  padding: 24px 16px; /* Padding for the question */
  transition: background-color 0.1s, transform 0.1s;
  transition: all 0.3s ease;
}

.faq-question:hover {
  background-color: #fff5f0;
  border-radius: 6px;
  font-size: 22px;
  transition: font-size 0.3s ease;
}

.faq-question span {
  font-size: 18px; /* Font size for the question */
  font-weight: 400; /* Make question text bold */
  transition: font-size 0.3s ease;
}

.faq-question:hover span {
  font-size: 22px;
  transition: font-size 0.3s ease;
}

.faq-question .arrow {
  flex-shrink: 0; /* Prevent arrow from shrinking */
  margin-left: 16px; /* Space between text and arrow */
  font-size: 24px; /* Increase size for better visibility */
  color: #999;
  font-weight: 300;
}

.faq-answer-wrapper {
  height: 0;
  overflow: hidden;
  transition: height 0.5s ease;
  border-bottom: 1px solid #ffffff;
}

.faq-answer {
  overflow: hidden;

  padding: 16px; /* Padding for the answer */
  padding-bottom: 32px;
  background-color: #ffffff; /* White background for the answer */
  font-size: 16px; /* Font size for the answer */
  line-height: 24px;
}

.faq-item.open {
  font-size: 22px;
  border-top: 1px solid #cdcdcd;
  border-bottom: 1px solid #cdcdcd;
  margin-top: 16px;
  margin-bottom: 16p;
  padding-top: 16px;
  padding-bottom: 16px;
}

.faq-item.open .faq-question {
  background-color: #ffffff; /* Darker background color when open */
}

.faq-item.open .faq-answer-wrapper {
  height: auto;
}

.faq-item.open .faq-question span {
  font-size: 28px; /* Font size when the FAQ item is open */
  font-weight: 700;
}

/* FAQ actions */
.faq-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.delete-button,
.edit-button {
  padding: 8px 16px;
  margin: 5px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

/* Change button color on hover */
.delete-button:hover,
.edit-button:hover {
  background-color: #0056b3; /* Darker blue color on hover */
  cursor: pointer;
}

/* FAQ List Styles */
.faq-list {
  flex: 1;
  overflow-y: scroll;

  padding: 16px;
}

.faq-page {
  overflow-y: scroll;
  overflow: visible;
}

.faq-list .faq-item .faq-question {
  line-height: 24px;
  margin-bottom: 18px;
  overflow-y: scroll;
}

.faq-list .faq-item .faq-actions {
  display: flex;
  justify-content: flex-end;
}

.faq-list .faq-item .faq-actions button {
  cursor: pointer;
  background-color: #fff;
  color: rgb(31, 31, 31);
  height: 36px;
  font-size: 18px;
}

/* Hover effect for faq-item */
.faq-item:hover {
  background-color: #ffffff; /* Light gray background color on hover */
}

/* Show the delete and edit buttons on hover */
.faq-item:hover .faq-actions {
  display: flex;
}

/* Font Awesome icons */
.delete-button svg,
.edit-button svg {
  width: 18px;
  height: 18px;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent overlay */
  z-index: 1000; /* Ensure it's on top of other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-md {
  background-color: white;
  padding: 32px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 900px;
}

.modal-content-md {
  text-align: left;
  display: flex;
  flex-direction: column;
}

.modal-content-md h2 {
  margin-bottom: 16px;
  font-weight: 400 !important;
}

.input-md,
.textarea-md {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  color: #000;
}

.button-md,
.button-md2 {
  padding: 8px 16px;
  font-size: 16px;
  background-color: #4caf50; /* Example background color */
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  outline: none;
  color: #ffffff;
  align-items: center; /* Vertically centers the content */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  gap: 8px;
}

.scrape {
  width: 140px;
  height: 44px;
  margin-bottom: 10px;
  background-color: rgb(232, 209, 117);
  color: black;
  margin-left: 8px;
}

.scrape i {
  display: flex;
  width: 16px;
  height: 16px;
  color: black;
}

.button-md2 {
  background-color: #97774b !important;
  color: white !important;
}

.button-md:hover,
.button-md:focus {
  background-color: #5eca50;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.ModalButtonGroup {
  display: flex; /* Ensure the container is a flex container */
  flex-direction: row;
  gap: 8px; /* Set the gap between items */
  justify-content: flex-end; /* Align items to the right */
}

.button-md2.save {
  background-color: #007bff !important;
  color: white;
}

.button-md2:hover,
.button-md2:focus {
  background-color: #7c4dff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.button-md2:active {
  background-color: #3700b3;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.button-md2.disabled {
  background-color: grey !important;
  color: #ccc !important;
  cursor: not-allowed;
}

/* Text Link Styles */
.text-links span {
  cursor: pointer; /* Set cursor to pointer for all spans within .text-links */
}

.text-links span:hover {
  text-decoration: underline; /* Underline on hover */
}

.textbutton span {
  padding: 10px;
  margin-right: 16px;
}

.textbutton span:hover {
  color: #ccc;
  cursor: pointer;
}

/* Section Header Styles */
.sectionHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.headerLeft {
  flex: 1; /* Take as much space as possible */
}

.headerRight {
  margin-left: 10px; /* Add some space between the button and the label */
}

.card-list-container {
  width: 40%;
  padding: 20px;
}

/* styles.css */
.pageMetaData {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1px solid #d3d3d3;
  width: 100%;
}

.pageName,
.categoryNumber,
.headerBanner {
  padding-bottom: 20px;
}

.pageNames {
  display: flex;
  flex-direction: column;
}

.headerBanner {
  display: flex;
  flex-direction: column;
  height: 400px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
}

.bannerSelection {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.bannerImage {
  width: 400px;
  height: auto;
  cursor: pointer;
  border: 2px solid transparent;
}

.bannerImage.selected {
  border: 2px solid blue;
}

.page-title.expandable {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 36px;
  padding-bottom: 36px;
  font-family: inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 24px;
}

.page-title.expandable:hover {
  background-color: #f4f4f4;
}

.page-title .chevron {
  margin-left: auto; /* Ensure the chevron stays at the far right */
  margin-right: 10px;
  font-size: 24px;
  color: #999;
  font-weight: 400;
}

.bannerImage {
  width: 100%;
  height: auto;
  cursor: pointer;
  border: 2px solid transparent;
  margin-bottom: 10px;
  transition: border 0.3s;
}

.bannerImage.selected {
  border: 2px solid #007bff;
}

.button-container {
  display: flex;
  justify-content: flex-end; /* Align the button to the right */
}

.text-links {
  color: #007bff;
}

.quill-editor-container {
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 10px;
  max-height: 350px;
}
.ql-toolbar {
  background-color: #f1f1f1;
  border-bottom: 1px solid #ccc;
  justify-content: flex-start !important; /* Aligns toolbar items to the start (left) */
  text-align: left !important;
}

.ql-toolbar button {
  color: #333; /* Changes the color of the button icons */
}

.ql-toolbar button:hover {
  background-color: #e2e2e2;
}
.ql-container {
  font-family: inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto;
  font-size: 18px !important;
  max-height: 500px;
  font-weight: 500;
}

.ql-editor {
  min-height: 200px; /* Adjust the height of the content area */
  max-height: 900px;
  max-height: 500px !important;
}
.ql-editor.ql-blank::before {
  color: #999;
  font-style: italic;
  content: attr(data-placeholder);
}

.faq-item-no-answer {
  color: rgb(181, 1, 1); /* Light red to indicate missing answers */
}

.categoryList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* Ensures that items can wrap onto the next line */
  padding: 10px 0;
  overflow: hidden; /* Hides any overflowing content */
  justify-content: flex-start; /* Aligns items to the start of the flex container */
}

.categoryPills {
  padding: 0;
  margin: 0;
  list-style-type: none; /* Removes default list bullet points */
  display: flex; /* Applies flexbox to the <ul> to layout children horizontally */
  flex-wrap: wrap; /* Allows items to wrap to the next line */
  width: 100%; /* Full width to contain all items */
}

.categoryPill {
  font-size: 22px;
  flex: 0 1 auto; /* Do not grow, allow shrink, and basis auto */
  margin: 5px;
  padding: 8px 16px;
  background-color: #fff5f0;
  border-radius: 24px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s, transform 0.3s;
  border: none;
  word-wrap: break-word; /* Allows text to wrap within the button */
  white-space: normal; /* Ensures text can wrap */
  line-height: 1.5; /* Improves readability of wrapped text */
}

.categoryPill:hover {
  transform: scale(1.05);
  background-color: #ffbe98;
}

.categoryPill.selected {
  background-color: #ffbe98;
}

.faqItems {
  display: flex;
  flex-direction: column;
  list-style-type: none; /* Remove default list-style */
  padding: 0;
  margin: 0;
}

.faq-item-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Add some space between items */
  cursor: pointer;
  font-family: Inter, Helvetica, sans-serif;
  padding: 0;
  transition: background-color 0.3s ease;
  width: 100%;
}

.draggable-handle {
  cursor: grab; /* Change cursor to grab */
  margin-right: 10px; /* Space between handle and content */
  display: inline-block;
}

.draggable-handle:before {
  content: "⋮⋮"; /* Use a vertical ellipsis as a draggable icon */
  font-size: 16px; /* Adjust icon size */
}

.loader {
  text-align: center;
  font-size: 18px;
  padding: 20px;
}

.fixed-width-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.fixed-width-table th,
.fixed-width-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.fixed-width-clicks {
  width: 10%;
  text-align: center;
}

.fixed-width-link {
  width: 35%;
  word-break: break-all; /* Ensures that long links will break into multiple lines */
}

.fixed-width-question {
  width: 55%;
  word-wrap: break-word; /* Ensures that long questions will wrap within the cell */
}

.mostViewedFAQs-container label {
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
}

.faq-list-container {
  max-width: 1200px;
  overflow: visible;
}

.premium {
  font-weight: 300;
  font-size: 14px;
}

.faq-buttons {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.faq-buttons button {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.ModalButtonGroup .button-md {
  background-color: #b5b5b5;
}

.ModalButtonGroup .save {
  background-color: #3a3a3a;
}

.FAQH3 {
  margin-bottom: 16px;
}
.FAQH3-2 {
  margin-top: 32px;
  margin-bottom: 16px;
}
