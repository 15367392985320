.chatbot {
  max-width: 100%;
  font-family: inter, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, sans-serif;
  color: #333;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

/* Chat messages styling */
.chat-messages {
  border-radius: 8px;
  flex: 1;
  overflow-y: auto;
}

.message.user {
  background-color: white;
  color: rgb(0, 0, 0);
  align-self: flex-end;
  font-size: 20px;
}

.message.assistant {
  background-color: #f2f2f2;
  color: #333;
  border-radius: 10px;
  align-self: flex-start;
  font-size: 20px;
}

.message {
  margin: 8px;
  padding: 12px;
  border-radius: 10px;
  max-width: 98%;
}

.bot {
  background-color: #000;
  display: none;
}

/* Input area styling */
.input-area {
  display: flex;
  background-color: white;
  vertical-align: middle;
  padding: 10px;
}

.input-field {
  border-radius: 10px;
  padding: 10px 20px;
  margin: 8px;
  border: 1px solid #ccc;
  height: 44px;
}

.input-group {
  width: 100%;
  background-color: #fff; /* Or any background color you prefer */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.send-button {
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: 44px;
  vertical-align: middle;
  background-color: #0056b3;
  border-radius: 4px;
  margin-top: auto;
  margin-bottom: auto;
}

.send-button {
  margin-left: 10px;
  padding: 10px 20px;
  background-color: #007bff;

  cursor: pointer;
}

.send-button:hover {
  background-color: #0056b3;
}

.button-group {
  background-color: #fff;
}

.messages-container {
  max-height: calc(
    100vh - 100px
  ); /* Adjust based on your header and footer size */
  overflow-y: auto;
  padding-bottom: 60px; /* Adjust based on your input group height */
  flex-grow: 1;
}

.button-group {
  display: flex;
  align-items: center;
}
